<template>
  <div class="h-100">
    <b-row class="h-100">
      <!-- <b-col style="background-color:#D8ECFD;" class="pl-8 pr-0 d-flex align-items-center justify-content-center"> -->
      <b-col style="background-image:url(/assets/images/bg-login.png);background-size: 100% 100%;" 
        class="pl-8 pr-0 d-lg-flex align-items-center justify-content-center d-none">
        <!-- <img src="/assets/images/login-island.svg" alt="" width="100%" style="z-index:0;"/> -->
        <!-- <div style="z-index:0;position:absolute;text-align:center; ">
          <h1 style="font-size:64px;font-weight:bold;color:#333333;">SIORMAS</h1>
          <p style="font-weight: 600;font-size: 24px;color:#333333;">Sistem Informasi Organisasi Kemasyarakatan</p>
        </div> -->

      </b-col>
      <b-col style="background-color:white;">
        <div class="d-flex justify-content-center" style="margin-top:32px;margin-bottom:30px;">
          <img src="/assets/images/logo1.svg" alt="" width="201px" height="87px" />
        </div>
        <b-row style="padding-left:51px;padding-right:51px;">
          <b-col>
            <b-card v-if="formMode == 'login' " class="shadow" style="padding-left:2rem;padding-right:2rem;">
              <div style="margin-top:8px;margin-bottom:22px;font-weight: 600;font-size: 20px;" class="d-flex justify-content-center">
                Silahkan Login
              </div>
              <b-row>
                <b-col>
                  <span style="font-weight:bold;">NIP</span>
                  <b-form-input class="mt-2"  v-model="$v.form.email.$model"
                    :state="validateState('email')" id="nip" type="text">
                    </b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col>
                  <span style="font-weight:bold;">Kata Sandi</span>
                  <b-form-input class="mt-2" type="password"
                    placeholder="Silahkan masukan kata sandi"
                    v-model="$v.form.password.$model"
                    @keyup.enter="login()"
                    :state="validateState('password')">
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right pt-2">
                  Lupa Kata Sandi? <a @click="formMode = 'reset'" style="cursor:pointer;">Klik Disini</a>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col>
                  <b-button v-if="!loading" @click="login()" size="lg" block variant="primary">
                    Login
                  </b-button>
                  <b-button v-else disabled size="lg" block variant="primary">
                    Sedang Di Proses...
                  </b-button>
                </b-col>
              </b-row>
                 <b-row class="pt-5 text-center">
                <b-col>
                  atau melalui
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col cols="6"> 
                  <b-button :disabled="loading" @click="ssoNasional()" size="lg" block variant="primary">
                    SSO Nasional
                  </b-button>
                </b-col>
                <b-col cols="6"> 
                  <b-button :disabled="loading" @click="ssoPolpum()" size="lg" block variant="primary">
                    SSO POLPUM
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col cols="6" class="text-right">
                  <a href="javascript:;" @click="showModalPrivasi = true" ref="privacyButton">Kebijakan Privasi</a>
                </b-col>
                <b-col cols="6"> 
                  <a href="javascript:;" @click="showModalSyarat = true" ref="conditionButton">Syarat dan Ketentuan</a>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="formMode == 'reset' " class="shadow" style="padding-left:2rem;padding-right:2rem;">
              <div style="margin-top:8px;margin-bottom:22px;font-weight: 600;font-size: 20px;" class="d-flex justify-content-center">
                Lupa Kata Sandi? Kami akan Bantu
              </div>
              <b-row>
                <b-col>
                  <span style="font-weight:bold;">Email Terdaftar</span>
                  <b-form-input class="mt-2" v-model="forget.email" type="text"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col>
                  <span style="font-weight:bold;">NIP</span>
                  <b-form-input v-model="forget.username" class="mt-2" type="text"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-20">
                <b-col class="d-flex justify-content-center">
                  <b-button @click="formMode = 'login'" size="lg" variant="outline-primary" style="width:190px;" class="mr-3">
                    Batal
                  </b-button>
                  <b-button @click="submitForget()" size="lg" variant="primary" style="width:190px;">
                    Kirim
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="formMode == 'emailsent' " class="shadow" style="padding-left:2rem;padding-right:2rem;">
              <div style="margin-top:8px;margin-bottom:10px;font-weight: 600;font-size: 20px;" class="d-flex justify-content-center">
                Sandi telah dikirim ke email anda
              </div>
              <p style="margin:0px; font-size:16px;text-align:center;">Silahkan Cek email anda untuk melihat sandi anda</p>
              <b-row >
                <b-col class="d-flex justify-content-center">
                  <img src="/assets/images/email-sent.svg" alt="" width="120px" style="margin-top:20px;" />
                </b-col>
              </b-row>
              <b-row class="pt-10">
                <b-col class="d-flex justify-content-center">
                  <b-button @click="formMode = 'login'" size="lg" variant="primary" style="width:400px;">
                    Login Kembali
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <SyaratKetentuan v-if="showModalSyarat" @close="showModalSyarat = false" />
    <KebijakanPrivasi v-if="showModalPrivasi" @close="showModalPrivasi = false" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import SyaratKetentuan from "./SyaratKetentuan.vue";
import KebijakanPrivasi from "./KebijakanPrivasi.vue";


export default {
  mixins: [validationMixin],
  name: "login-kesbangpol",
  components: {
    SyaratKetentuan,
    KebijakanPrivasi
  },
  data(){
    return {
      loading: false,
      formMode: 'login',
      form: {
        email: "",
        password: ""
      },
      forget: {
        username: '',
        email: ''
      },
      showModalSyarat: false,
      showModalPrivasi: false
    }
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      }
    },
    forget: {
      username: { required },
      email: { required }
    }
  },
  mounted(){
    // Query Parameters for terms & conditions
    const allowedTerms = ['kebijakan privasi', 'syarat ketentuan'];
    const term = this.$route.query.term;

    setTimeout(() => {
      if (term == allowedTerms[0]) {
        this.$refs.privacyButton.click()
        
      } else if (term == allowedTerms[1]) {
        this.$refs.conditionButton.click()
      }
    }, 100);

    if(localStorage.getItem('token')) {
      this.$store
        .dispatch("getProfile").then(()=>{
          this.$router.push('/dashboard')
        }).catch(()=>{
          localStorage.removeItem('token')
          location.reload()
        })
    };
    // Enable recaptcha badge
    setTimeout(() => {
        const recaptcha = this.$recaptchaInstance
        recaptcha.showBadge()
          }, 500)
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    submitForget: function() {
      // e.preventDefault();
      let body = {
        "username": this.forget.username,
        "email": this.forget.email,
        "url_callback": process.env.VUE_APP_APPLICATION_BASE_URL + "/polpum/resetsandi",
        "nonOrmas": true
      }
      this.$store.dispatch('lupaPassword', body).then(() =>{
        this.formMode = 'login'
        Swal.fire({
        icon: "success",
        title: "Sandi Telah Dikirim ke Email Anda",
        text: "Silahkan cek email anda untuk melihat sandi anda",
        confirmButtonColor: "#063A69",
        confirmButtonText: "Ok"
        // footer: '<a href="">Why do I have this issue?</a>'
      })
      this.$router.push('/polpum');
      }).catch((err) =>{
        Swal.fire({
          title: "Gagal Submit",
          text: err,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
      
    },
    async login() {
      this.loading = true
      let context = this
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loading = false
        return;
      }

      const username = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const reCaptcha = await this.recaptcha();

      this.$store
        .dispatch("login", {
          "username": username,
          "password": password,
          "reCaptcha": reCaptcha,
          "nonOrmas": true
        })
        .then(() => {
          // if(!res.data.isLengkap) this.$router.push('/formdataormas')
          // else this.$router.push({ name: "dashboard" })
          this.$router.push({ name: "dashboard" })
          this.loading = false
        })
        .catch(async response => {
           context.loading = false
           if (response.message === 'Request failed with status code 401') {
             await Swal.fire(
              {
                title: "Gagal Login",
                icon: "warning",
                text:
                  "Silahkan periksa NIP dan Password anda kembali",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
                context.loading = false
              }
            );
           } else {
            await Swal.fire( 
              {
                title: "Terjadi Kesalahan",
                icon: "warning",
                text:
                  "Silahkan coba lagi beberapa saat",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
                context.loading = false
              }
            );
           }
          
        })
    }, 
    ssoNasional(){
      var clientid = process.env.VUE_APP_SSO_CLIENTID
      var baseURI  = process.env.VUE_APP_SSO_BASE_URI
      // var callbackUri = 'http://localhost:4000/login-callback'
      var callbackUri = process.env.VUE_APP_APPLICATION_BASE_URL + '/login-callback'
      location.replace(baseURI + '/auth/realms/SPBE/protocol/openid-connect/auth?client_id=' + clientid + '&state=&redirect_uri=' + callbackUri + '&response_type=code')
    },
    ssoPolpum(){
      var baseURI = process.env.VUE_APP_SSO_POLPUM_BASE_URI
      // var callbackUri = 'http://localhost:4000/login-callback'
      var callbackUri = process.env.VUE_APP_APPLICATION_BASE_URL + '/login-callback'
      location.replace(baseURI + '/rs/auth/web/check-login?redirect-url=' + callbackUri)
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()
  
        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
    
          return token
    },
    
  },
  beforeDestroy() {
    // Hide badge recaptcha
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  }
};
</script>
